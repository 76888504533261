import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map, size } from 'lodash/fp'
import { mapIndexed } from 'helpers'

import { Layout } from 'src/components/Layout'
import { MarkdownContent } from 'src/components/MarkdownContent'

import { AccordionList } from 'src/slices/AccordionList'
import { ButtonList } from 'src/slices/ButtonList'
import { PageTitle } from 'src/slices/PageTitle'
import { Summary } from 'src/slices/Summary'

const ResourcesPage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)
  const groups = get('frontmatter.groups', page)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <PageTitle>{get('frontmatter.title', page)}</PageTitle>
      <Summary markdown={get('frontmatter.description', page)} />
      <ButtonList pb={0} maxWidth="medium">
        {map(
          group => (
            <ButtonList.Button
              key={get('path', group)}
              to={`#${get('path', group)}`}
            >
              {get('title', group)}
            </ButtonList.Button>
          ),
          groups
        )}
      </ButtonList>
      {mapIndexed(
        ([index, group]) => (
          <AccordionList
            key={get('path', group)}
            id={get('path', group)}
            heading={get('title', group)}
            descriptionMarkdown={get('description', group)}
            pb={index >= size(groups) - 1 ? [6, 10] : 0}
          >
            {map(
              content => (
                <AccordionList.Accordion heading={get('title', content)}>
                  <MarkdownContent markdown={get('content', content)} />
                </AccordionList.Accordion>
              ),
              get('contents', group)
            )}
          </AccordionList>
        ),
        groups
      )}
    </Layout>
  )
}

export default ResourcesPage

export const query = graphql`
  query ResourcesPage {
    file(relativePath: { eq: "customPages/resources.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          groups {
            title
            path
            description
            contents {
              title
              content
            }
          }
        }
      }
    }
  }
`
